@import "../assets/colors.scss";

.rootWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainContentWrapper {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.footerWrapper {
  width: 100%;
  height: 1em;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: $teluspurple;
}

.routesWrapper {
  flex: 1;
  height: auto;
}
