@import "../../assets/colors.scss";
.text {
  font-size: 0.6rem !important;
  color: $fontclr;
  font-weight: 500;
}
.buttoncls {
  background-color: #4b286d !important;
  font-size: 0.6rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 0.8rem !important;
  color: white !important;
  width: 8rem !important;
  // align-self:  ;
  border-radius: 1.4rem !important;
  margin-left: 1rem !important;
  // margin-top: 1.5rem !important;
}
.normalBtncls {
  background-color: white !important;
  font-size: 0.6rem !important;
  padding-left: 0.1rem !important;
  padding-right: 0.1rem !important;
  border-radius: 1rem !important;
  color: #3e4245 !important;
  width: 8rem !important;

  // border-width: 0.05rem !important;
  // border-color: #
  border: 1px solid #dcdcdc !important;
  // margin-top: 1.5rem !important;
}
.linkText {
  color: #1976d2;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
  cursor: pointer;
}
.rowText {
  font-weight: 400 !important;
  font-size: 0.7rem !important;
  color: $rowtext !important;
  margin-left: 0.5rem !important;
}
