@import "../../assets/colors.scss";
.text {
  font-weight: 400;
  font-size: 1.2rem;
  //   color: rgba(75, 40, 109, 1);
}
.h_oneText {
  // color: $white;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  margin-bottom: 1rem !important;
  margin-top: 1.2rem !important;
  line-height: 1.063rem !important;
  color: #666666 !important;
}
.h2Text {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #666666 !important;
  line-height: 1.063rem !important;
}
.h3Text {
  font-size: 0.6rem !important;
  font-weight: 700 !important;
  margin-top: 1rem !important;
  color: #3e4245 !important;
  line-height: 1.063rem !important;
}
.buttoncls {
  background-color: #4b286d !important;
  font-size: 0.8rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0.8rem !important;
  color: white !important;
  width: 10rem !important;
  align-self: center;
  border-radius: 1.4rem !important;
  margin-top: 1.5rem !important;
}
.bgtransparent {
  background-color: transparent !important;
  border: 1px solid #4b286d !important;
  color: #4b286d !important;
  margin-right: 1rem !important;
}
.btnformcontrol {
  margin-top: 1.5rem !important;
}
.btndropdown {
  background-color: #4b286d !important;
  font-size: 0.8rem !important;
  padding: 0.4rem 0.5rem 0.4rem 1.6rem !important;
  color: white !important;
  width: 10rem !important;
  align-self: center;
  text-align: center;
  border-radius: 1.4rem !important;

  svg{
    color: white;
  }
}
