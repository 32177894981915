@import "../../assets/colors.scss";
.headerclass {
  background-color: $telusBg !important;
  font-size: 1.4rem !important;
  // padding-left: 1rem !important;
  // padding-right: 1rem !important;
  // border-radius: 0.8rem !important;
  // color: $teluspurple !important;
  //   color: $white;
}
.headerText {
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: $teluspurple !important;
}
.rowText {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  // color: $teluspurple !important;
}
