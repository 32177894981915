@import "../../assets/colors.scss";

.boxContainer {
	width: 100%;
	position: fixed;
	bottom: 0;
	background-color: $telusBg;
	border-top: 2px solid $accessibleFreen;
	display: flex;
	flex-direction: column;
}

.contentContainer {
	width: 95%;
	margin: auto;
	padding: 1em;
}

.buttonWrapper {
	display: flex;
	gap: 1em;
	margin: auto;
	margin-top: 2em;
	justify-content: flex-end;
}

.button {
	width: auto;
	padding: 0.6em 2rem 0.6em 2rem;
	background-color: $accessibleFreen;
	color: $white;
	cursor: pointer;
	margin-bottom: 1rem;
	border-radius: 1.4rem 1.4rem 1.4rem 1.4rem;
	border: 2px solid $accessibleFreen;
	&:hover {
		background-color: white;
		color: $accessibleFreen;
	}
}
