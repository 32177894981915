@import "../../../assets/colors.scss";
.headerclass {
  background-color: $tableHeader !important;
  font-size: 0.8rem !important;
  // padding-left: 1rem !important;
  // padding-right: 1rem !important;
  // border-radius: 0.8rem !important;
  // color: $teluspurple !important;
  //   color: $white;
}
.headerText {
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  color: $rowtext !important;
}

.headerText:hover {
  cursor: pointer;
}

.filterIcon {
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  color: $rowtext !important;
  vertical-align: middle;
  margin-left: 0.1rem;
  // verticalAlign:"middle"
}
.rowText {
  font-weight: 400 !important;
  font-size: 0.7rem !important;
  color: $rowtext !important;
}
.table {
  min-width: 650 !important;
  border-collapse: separate !important;
  border-spacing: 0px 10px !important;
  // background-color: $tablebg !important;
}
.MuiTable-root {
  // border-collapse: separate !important;
  border-spacing: 0 10px !important;
  border: transparent !important;
  background-color: $tablebg !important;
}
