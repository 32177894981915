.rs-picker-default .rs-picker-toggle.rs-btn {
  /* padding-top: 7px; */
  /* padding-bottom: 7px; */
  width: 100%;
}

/* picked date align */
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 99999 !important;
  /* padding-right: 67px; */
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
  /* padding-left: 44px; */
}
/* calander align */
/* .rs-picker-toggle-caret {
  display: inline-block;
  margin-right: 240px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
} */

/* ok button style */
.rs-picker-toolbar-right-btn-ok {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 1.875rem;
  color: #fff;
  background-color: red;
  width: 6.25rem;
}

/* range style */
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 1.25rem;
  display: inline-block;
}

.rs-calendar-table-cell-content {
  font-size: 1rem;
  font-family: "Helvetica", sans-serif;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
  /* -webkit-box-shadow: var(--rs-state-focus-shadow); */
  box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
  /* box-shadow: var(--rs-state-focus-shadow); */
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #20b283;
  border-width: 2px;
  /* box-shadow: 0 0 0 3px rgb(52 152 255 / 25%); */

  /* border-color: var(--rs-input-focus-border); */
}

/* .rs-anim-fade
  rs-anim-in
  rs-picker-daterange-menu
  rs-picker-menu
  placement-bottom-start {
} */

/* .rs-picker-daterange-panel,
.rs-picker-daterange-panel-show-one-calendar {
  height: 20rem;
  overflow-y: scroll;
} */

.rs-anim-fade,
.rs-anim-in,
.rs-picker-daterange-menu,
.rs-picker-menu,
.placement-bottom-start {
  overflow-y: auto;
  height: auto;
  /* @media screen and (min-height: 400px) {
    height: 20rem;
  }
  @media screen and (min-height: 420px) {
    height: 21rem;
  }
  @media screen and (min-height: 440px) {
    height: 22rem;
  }

  @media screen and (min-height: 460px) {
    height: 22.5rem;
  }
  @media screen and (min-height: 480px) {
    height: 22.75rem;
  }

  @media screen and (min-height: 500px) {
    height: 22.8rem;
  }
  @media screen and (min-height: 520px) {
    height: 22.9rem;
  }

  @media screen and (min-height: 540px) {
    height: 20rem;
  }
  @media screen and (min-height: 560px) {
    height: 23.1rem;
  }
  @media screen and (min-height: 580px) {
    height: 23.2rem;
  }
  @media screen and (min-height: 600px) {
    height: 23.3rem;
  }
  @media screen and (min-height: 620px) {
    height: 23.4rem;
  }
  @media screen and (min-height: 640px) {
    height: 23.5rem;
  }
  @media screen and (min-height: 660px) {
    height: 23.6rem;
  }
  @media screen and (min-height: 680px) {
    height: 23.7rem;
  }

  @media screen and (min-height: 700px) {
    height: 23.8rem;
  }
  @media screen and (min-height: 720px) {
    height: 23.9rem;
  }
  @media screen and (min-height: 740px) {
    height: 24rem;
  }
  @media screen and (min-height: 760px) {
    height: 24.1rem;
  }
  @media screen and (min-height: 780px) {
    height: 24.2rem;
  }
  @media screen and (min-height: 800px) {
    height: 24.3rem;
  } */
}
.rs-calendar-table-header-cell-content {
  font-size: 0.8rem;
  /* font-family: "Helvetica", sans-serif; */
}
.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  display: table-cell;
  /* width: 1%; */
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
}

.rs-picker-menu {
  z-index: 99999 !important;
}
.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  padding: 0px 0px 0px 0px;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  box-shadow: none;
}
/* .dates {
  text-align: center;
  padding-top: 30px;
} */
/* left side days filter */
.rs-btn-link {
  color: #19181a;
  background-color: transparent;
  border: none;
  font-family: "Helvetica", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
/* selected Date value */
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black;
  /* color: var(--rs-picker-value); */
}
.rs-btn {
  font-size: 0.9rem;
  font-family: "Helvetica", sans-serif;
  font-weight: 500;
  color: "#8e8e93";
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background-color: #223e80;
  /* background-color: var(--rs-calendar-cell-selected-hover-bg); */
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
}
/* high lighted dates*/
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: #223e80;
  /* background-color: var(--rs-calendar-cell-selected-hover-bg); */
  color: #fff;
  /* color: var(--rs-calendar-date-selected-text); */
}

.rs-calendar-table-cell .rs-calendar-table-cell-is-today {
}

.rs-btn-primary {
  background-color: #223e80;
  color: white;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
  color: #223e80;
  font-weight: 600;
  font-size: 0.9rem;
}

.rs-picker-daterange-header {
  font-size: 1rem;
  font-family: "Helvetica", sans-serif;
}
/* Latest */

/* .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 20px;
  height: 20px;
} */
.rs-calendar-table-cell-content {
  font-size: 0.8rem;
}
.rs-calendar {
  padding-top: 7px;
  position: relative;
  min-height: 15rem;
  min-width: 15.625rem;

  overflow: hidden;
}
.rs-picker-daterange-menu .rs-calendar {
  height: 15rem;
}
.rs-picker-toolbar {
  padding: 0.625rem;
}
.rs-anim-fade
  .rs-anim-in
  .placement-bottom-start
  .rs-picker-daterange-menu
  .rs-picker-menu {
  height: 21rem;
}
.rs-picker-daterange-predefined {
  height: 21rem;
}

.MuiPhoneNumber-flagButton{
  margin-left: 10px !important;
}