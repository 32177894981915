@import "../../assets/colors.scss";
.appBardiv {
  background: $white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 2rem;
}
.innerDiv {
  background-image: linear-gradient(
    rgba(102, 204, 0, 0.2),
    rgba(247, 249, 248, 0.2)
  );
  height: 5rem;
  width: inherit;
  margin-right: 1.8rem;
  margin-left: 1.8rem;
  margin-top: -1rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 1rem;
}
.text {
  font-weight: 400;
  font-size: 1.2rem;
  color: rgba(75, 40, 109, 1);
}
.logoImage {
  margin: 0.5rem;
  max-width: 400px;
  min-width: 200px;
  cursor: pointer;
}
.headertext {
  font-weight: 500;
  font-size: 2rem;
  color: rgba(75, 40, 109, 1);
  align-self: center;
  // padding-left: 4rem;
}

.menuItem:hover{
  cursor: pointer;
}
