.footerWrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-items: center;
	font-size: xx-small;
}

.footerItems {
	color: white;
	text-decoration: none;
}
