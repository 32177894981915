@import "../../assets/colors.scss";
.buttoncls {
  background-color: #4b286d !important;
  font-size: 0.8rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0.8rem !important;
  color: white !important;
  width: 10rem !important;
  align-self: center;
  border-radius: 1.4rem !important;
}
.text {
  font-weight: 400;
  font-size: 1.2rem;
  //   color: rgba(75, 40, 109, 1);
}
