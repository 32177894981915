@import "../../assets/colors.scss";

.buttonSave {
	width: auto;
	padding: 0.6em 2rem 0.6em 2rem;
	background-color: $accessibleFreen;
	color: $white;
	cursor: pointer;
	border-radius: 1.4rem 1.4rem 1.4rem 1.4rem;
	border: 2px solid $accessibleFreen;
	&:hover {
		background-color: white;
		color: $accessibleFreen;
	}
}

.buttonClose {
	width: auto;
	padding: 0.6em 2rem 0.6em 2rem;
	background-color: $telusBg;
	color: $accessibleFreen;
	cursor: pointer;
	border-radius: 1.4rem 1.4rem 1.4rem 1.4rem;
	border: 2px solid $accessibleFreen;
}
