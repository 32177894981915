$primaryTelusGreen: #66cc00;
$accessibleFreen: #2b8000;
$telusBg: #f4f4f7;
$teluspurple: #4b286d;
$tablebg: #f2f4f6;
$fontclr: #778ca2;
$backgroundgray: #f1f5f8;
$btnClr: #7e5aa2;
$progressbarClr: #4b286d;
$tableHeader: #e6e6e6;
$rowtext: #3e4245;
$columTable: #f2f2f2;
$white: #ffffff;
$black: #000000;
$borderClrGry: #767878;
$primaryText: #2d2d2d;
$midOrange: #f06400;
$lightSuccess: #dde99f;
$grassGreen: #009a17;
$lightDanger: #fad9d2;
$error: #e7401e;
$bloodOrange: #ee4c14;
$lightOrange: #ed8b00;
$headerGrey: #eeeeee;
$gallery: #eeeeee;
$darkGrey: #bbbbbb;
$backgroundBlue: #f6f8fb;
$Sand: #f6f6f6;
$mist: #f6f8fb;
$ActiveBlue: #457ee7;
$petrolBlue: #004a5d;
$petrolBlue20: #ccdbdf;
$petrolBlue40: #99b7be;
$aquaBlue: #00b1af;
$thunder: #2d2d2d;
$steel: #545656;
$steel20: #dddddd;
$steel40: #bbbbbb;
$steel60: #989a9a;
$BoxShadow: 0px 2px 4px #cecece;
$genericBoxShadow: 0px 4px 4px #00000040;
$projectScreenCardBoxShadow: 0px 4px 20px rgba(83, 100, 163, 0.21);
$projectCardMaxWidth: 29rem;
$workflowCardMaxWidth: 29rem;
$projectCardMargin: 1rem;
// additions
$darkBlue: #082057;
$dullDarkBlue: #0a1d49;
$semiDarkBlue: #5364a3;
$dawnBlue: #2a4686;
$selectionBlue: #0c6acb;
$lightPurple: #e3e9ff;
$bluishGray: #d3daf1;
$grayshade: #e5e5e5;
$graydisabled: #a4a4a4;
$graydarkshade: #3f3f3f;
//Background color
$paperWhite: #fafafa;
$paperGray: #ececec;
$borderClrLightGry: #d2d2d2;
$cardBlue: #6d82d0;
$lightGray: #bdbdbd;
$transparent: #ffffff00;
$textGrey: #464a53;
$descriptionGrey: #686868;
$green: #26b484;
$red: #e74133;
$lightBorderClrGry: #c4c4c4;
$lightRowclrGry: #f1f1f1;

// template colors
$dropDown: #e3e9ff;

$highlightBlue: #6d82d04d;
