@import "../../assets/colors.scss";
.loginInput {
  width: inherit;
  padding: 10px !important;
  margin-bottom: 10px !important;
  // border: 1px solid #ccc !important;
}
.loginPasswordInput{
  width: inherit;
  padding: 10px !important;
}
.lableText {
  color: $rowtext !important;
  font-size: 0.8rem !important;
}
.buttoncls {
  background-color: #4b286d !important;
  font-size: 0.6rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 0.8rem !important;
  color: white !important;
  width: 10rem !important;
  // align-self:  ;
  border-radius: 1.4rem !important;
  // margin-top: 1.5rem !important;
}

.normalBtncls {
  background-color: white !important;
  font-size: 0.6rem !important;
  padding-left: 0.1rem !important;
  padding-right: 0.1rem !important;
  border-radius: 1rem !important;
  color: #3e4245 !important;
  width: 8rem !important;

  // border-width: 0.05rem !important;
  // border-color: #
  border: 1px solid #dcdcdc !important;
  // margin-top: 1.5rem !important;
}
.errorMsg {
  font-size: 0.8rem !important;
  margin-right: 0.2rem !important;
}
