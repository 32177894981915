@import "../../assets/colors.scss";
.tableNames {
  background-color: $telusBg !important;
  font-size: 1.4rem !important;
  padding: 0.5rem !important;
  //   padding-left: 1rem !important;
  //   padding-right: 1rem !important;
  color: $rowtext !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid #dcdcdc !important;

  //   border-width: 1px !important;
  //   border-color: black !important;
  //   justify-content: center !important;
  //   color: $white;
}

.headerText {
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: $teluspurple !important;
}
.selectedTableName {
  background-color: white !important;
  font-size: 1.4rem !important;
  padding: 0.5rem !important;
  //   padding-left: 1rem !important;
  //   padding-right: 1rem !important;
  color: $rowtext !important;
  display: flex !important;
  align-items: center !important;
  border-left: 5px solid $teluspurple !important;
}
.nodataView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.titleText {
  font-weight: 500 !important;
  color: $rowtext !important;
  font-size: 1rem !important;
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;
}
.text {
  font-weight: 400 !important;
  color: $rowtext !important;
  font-size: 0.7rem !important;
}
.headerclass {
  background-color: $tableHeader !important;
  font-size: 0.8rem !important;
  // padding-left: 1rem !important;
  // padding-right: 1rem !important;
  // border-radius: 0.8rem !important;
  // color: $teluspurple !important;
  //   color: $white;
}
