@import "../../../assets/colors.scss";

.body {
  background-image: url("../../../assets/loginbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Helvetica", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);
  background-color: rgba(255, 255, 255, 0.5);
}

.loginContainer {
  // background-image: url("../../assets/container_bg.png");
  background-repeat: no-repeat;

  max-width: 300px;
  width: 90%;
  padding: 1.2rem;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.8);
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}
.loginOverlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.logoPlaceholder {
  // // background-image: url('logo.jpg');
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center top;
  // width: 200px;
  color: rgba(0, 0, 0, 0.6);
  height: auto;
  margin: 1rem;
  align-self: start;
}

.loginForm {
  text-align: center;
}

.loginInput {
  width: 90%;
  padding: 0.5rem;

}

.loginButton {
  width: 100%;
  padding: 0.8rem;
  background-color: $teluspurple;
  color: $white;
  cursor: pointer;
  margin-bottom: 1rem;
  border-radius: 1.4rem 1.4rem 1.4rem 1.4rem;
}

.passwordToggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: auto;
  padding-left: 0.5rem;
  // text-align: left;
  // margin-bottom: 0.5rem;
  // align-items: center;
}

.passwordToggle label {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}
